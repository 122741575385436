import React from 'react'

const Logo = () => {
    return (
        <div>
            <svg width="247" height="93" viewBox="0 0 247 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M69.6186 27.2959H58.6834C57.2044 41.8633 55.7254 56.4403 54.2464 71.0268H32.5836L36.9276 27.2959C36.5913 27.2959 36.3694 27.2528 36.1404 27.2528C32.9915 27.2528 29.8427 27.2528 26.6866 27.2528C26.0497 27.2528 25.8851 27.4467 25.8279 28.0717C25.5201 31.5268 25.1122 34.9675 24.8116 38.4154C24.5111 41.8633 24.1676 45.2537 23.8312 48.6082C23.4376 52.5733 23.0297 56.5097 22.6361 60.4891C22.3069 63.8078 21.992 67.1335 21.6699 70.4522C21.6699 70.6245 21.6341 70.7969 21.6127 70.9909H0C1.98474 50.9595 3.96471 30.9497 5.93991 10.9615H93.092C90.3629 38.3388 87.6387 65.6849 84.9192 93H63.0775C65.2603 71.0627 67.4359 49.2188 69.6186 27.2959Z" fill="#DFBC3E" />
                <path d="M247 10.983C244.982 31 242.966 51.0074 240.953 71.0052H219.233C219.634 67.4137 220.041 63.8221 220.457 60.1587C220.29 60.2475 220.132 60.3509 219.984 60.4676C216.37 63.7144 212.749 66.9324 209.164 70.2295C208.894 70.4976 208.573 70.7073 208.22 70.8457C207.867 70.9841 207.489 71.0482 207.11 71.034C200.576 71.034 194.042 71.034 187.508 71.034H186.535L192.51 10.983H247ZM212.613 27.2959C211.697 36.4041 210.788 45.4548 209.858 54.7355C213.486 54.7355 216.928 54.7355 220.378 54.7355C220.505 54.7043 220.623 54.6412 220.719 54.552C220.815 54.4628 220.888 54.3504 220.929 54.2255C221.129 52.8319 221.272 51.424 221.408 50.0161C221.63 47.6026 221.823 45.1819 222.052 42.7684C222.296 40.1824 222.553 37.5965 222.811 35.0106C222.947 33.7104 223.104 32.4031 223.233 31.103C223.362 29.8028 223.448 28.6104 223.562 27.3246L212.613 27.2959Z" fill="#DFBC3E" />
                <path d="M97.0066 10.9543H151.518C151.36 12.5848 151.217 14.1723 151.06 15.7598C150.773 18.5899 150.344 21.4129 150.222 24.2503C150.101 27.0876 149.156 29.4868 148.254 32.0152C146.301 37.5103 144.325 42.9982 142.386 48.5005C142.34 48.7591 142.196 48.9895 141.983 49.1426C141.771 49.2956 141.507 49.359 141.248 49.3194C134.535 49.2763 127.823 49.2763 121.11 49.2619C120.881 49.2619 120.659 49.2619 120.308 49.2619L128.102 27.3534H117.145C116.243 36.3897 115.342 45.4333 114.426 54.5559H147.167C146.601 60.0797 146.043 65.5173 145.478 71.0053H91.0881C93.049 50.9643 95.0218 30.9473 97.0066 10.9543Z" fill="#DFBC3E" />
                <path d="M156.642 0H178.462C178.126 3.5341 177.789 7.09693 177.446 10.7747H188.574C188.023 16.3201 187.486 21.7505 186.935 27.2959H175.75C174.841 36.44 173.939 45.498 173.023 54.6636H183.922L182.391 71.0412H149.657C151.983 47.3153 154.309 23.7043 156.642 0Z" fill="#DFBC3E" />
            </svg>
        </div>

    )
}

export default Logo